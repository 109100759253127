import {currencyFormatCustom} from "../../helpers";
import moment from "moment";

export default function payTariff() {
    const wrapper = document.querySelector('.pay_tariff');

    if (wrapper) {

        const tariffCards = document.querySelectorAll('.card__item-tariff');


        // onLoad js
        tariffCards[0].classList.add('card__active-tariff');
        switchCard(tariffCards[0]);

        // onClick
        [...tariffCards].forEach(function (tariffCard) {

            tariffCard.addEventListener('click', function () {
                [...tariffCards].forEach(function (tariff) {
                    tariff.classList.remove('card__active-tariff')
                })
                tariffCard.classList.add('card__active-tariff');

                switchCard(tariffCard);

            })

        })
    }

    function switchCard (card) {

        const infoWrapper = wrapper.querySelector('.info-container')
        const sumText = infoWrapper.querySelector('.sum-tariff');
        const dateEndText = infoWrapper.querySelector('.date_end-tariff');
        const dateEndDesc = document.querySelector('.date_end-tariff-desc');
        const humanityDescTextTimePay = wrapper.querySelector('.desc-text-time-pay');
        const hiddenInputMonthEndTariff = wrapper.querySelector('#month_end')
        const hiddenInputSumTariff = wrapper.querySelector('#subscription_sum')

        const sum = card.dataset.sum;
        const currency = card.dataset.currency;
        const month = card.dataset.month;
        const id = card.dataset.id;

        const cardTitle = card.querySelector('.time-tariff');

        const dateEnd = moment().add(month, 'M').format('DD.MM.YYYY');

        sumText.innerHTML = currencyFormatCustom(sum / 100, currency);

        dateEndText.innerHTML = dateEnd;
        dateEndDesc.innerHTML = dateEnd;

        humanityDescTextTimePay.innerHTML = cardTitle.innerHTML === '1 год' ? 'год' :
            cardTitle.innerHTML === '1 месяц' ? 'месяц' : cardTitle.innerHTML;

        hiddenInputMonthEndTariff.value = month;
        hiddenInputSumTariff.value = sum;
    }
}