import $ from "jquery";
import {initBootstrap} from "./bootstrap";
import {InputMask} from "imask";
import {SUM_MASK} from "./variables";
import Cache from './compenents/CacheController';
import {currencyFormat, inlineInputAddHint, inlineInputRemoveHint} from "./helpers";
import api from "./compenents/ApiController";
import mousePopup from "./compenents/mousePopup";

const cache = new Cache();

const mainScreen = () => {
    initBootstrap({
        tooltip: false,
        popover: false,
        toasts: true
    });
    toastAutoShow();
    const modals = document.querySelectorAll('.blur-portal');
    modals.forEach(modal => {
        const modalCloseButton = modal?.querySelector('.modal__close');
        modalCloseButton?.addEventListener('click', () => {
            modal.classList.toggle("animate");
            setTimeout(() => modal.classList.toggle("active"), 400);
        })
        const modalCloseButtonText = modal?.querySelector('.modal__close-btn');
        modalCloseButtonText?.addEventListener('click', () => {
            modal.classList.toggle("animate");
            setTimeout(() => modal.classList.toggle("active"), 400);
        })
    })
    bidModal();
    limitModal();
    setAccountId();
};


const setAccountId = () => {
    if(document.location.href.includes('/web/') && document.querySelector('.tab-menu__container')) {
        sessionStorage.setItem('account_id', document.location.href.split('/web/')[1].split('/')[0]);
    }

}

const limitModal = () => {
    const triggers = document.querySelectorAll('.limit-modal');
    if (!triggers) return;

    triggers.forEach(trigger => {
        const triggerCurrency = trigger.getAttribute('data-currency');
        const modal = document.querySelector('.limit-modal-' + triggerCurrency);
        if (!modal) return;
        trigger.addEventListener('click', () => {
            modal.classList.add("active");
            setTimeout(() => modal.classList.add("animate"), 30);
        })
    })

}

const modal = document.querySelector('.blur-modal__bid');
const commissionInput = document.querySelector(".blur-modal__bid .desc-item .desc-value");
const sumInput = document.querySelector(".blur-modal__bid .sum-of-transaction");
const commissionLoader = document.querySelector('.commission-wrapper .modal__loader');
const sumWrapper = document.querySelector('.blur-modal__bid .sum-wrapper');
const amountWrapper = document.querySelector('.blur-modal__bid .amount-wrapper');

const type = 'topup';
let masked;
let maskedCommission;
if (sumInput) masked = new InputMask(sumInput, SUM_MASK);
if (commissionInput) maskedCommission = new InputMask(commissionInput, SUM_MASK);

let commissionFetching = false;
let timer = null;


let tries = 2;
let error = false;
const bidModal = () => {
    if (!modal) return;
    buttonBlock(modal);
    onPressCopyButton();
    onPressSingleCopyButtons();
    if (!sumInput) return;
    sumInput.addEventListener('input', () => submitInput('sum'));
    commissionInput.addEventListener('input', () => submitInput('commission'));
    submitButtonDisabler();
};

const submitButtonDisabler = () => {
    const button = modal.querySelector('.transaction__button');
    button.addEventListener('click', e => {
        if (error) return e.preventDefault();
        if (commissionFetching) return e.preventDefault();
        const sumWrapper = document.querySelector('.blur-modal__bid .sum-wrapper');
        const amountWrapper = document.querySelector('.blur-modal__bid .amount-wrapper');
        if (!sumWrapper || sumWrapper.classList.contains('inline-input__wrapper-error')) return e.preventDefault();
        if (!amountWrapper || amountWrapper.classList.contains('inline-input__wrapper-error')) return e.preventDefault();
        const sumInput = sumWrapper.querySelector('input');
        const amountInput = amountWrapper.querySelector('input');
        if (!sumInput || sumInput.value.length < 1) return e.preventDefault();
        if (!amountInput || amountInput.value.length < 1) return e.preventDefault();
    })

}

const onPressSingleCopyButtons = () => {
    const wrapper = document.querySelector('.props-card');

    if (wrapper) {

        const recipientBlock = wrapper.querySelector('#recipient-name');
        const numberAccountBlock = wrapper.querySelector('#number-account');
        const recipientBankBlock = wrapper.querySelector('#recipient-bank');
        const bikBlock = wrapper.querySelector('#bik');
        const correspondentBlock = wrapper.querySelector('#correspondent-account');
        const kppBlock = wrapper.querySelector('#kpp');
        const innBlock = wrapper.querySelector('#inn');

        recipientBlock.addEventListener('click', function (event) {
            navigator.clipboard.writeText(recipientBlock.innerHTML);
            mousePopup('Скопировано', event)
        })
        numberAccountBlock.addEventListener('click', function (event) {
            navigator.clipboard.writeText(numberAccountBlock.innerHTML);
            mousePopup('Скопировано', event)
        })
        recipientBankBlock.addEventListener('click', function (event) {
            navigator.clipboard.writeText(recipientBankBlock.innerHTML);
            mousePopup('Скопировано', event)
        })
        bikBlock.addEventListener('click', function (event) {
            navigator.clipboard.writeText(bikBlock.innerHTML);
            mousePopup('Скопировано', event)
        })
        correspondentBlock.addEventListener('click', function (event) {
            navigator.clipboard.writeText(correspondentBlock.innerHTML);
            mousePopup('Скопировано', event)
        })
        kppBlock.addEventListener('click', function (event) {
            navigator.clipboard.writeText(kppBlock.innerHTML);
            mousePopup('Скопировано', event)
        })
        innBlock.addEventListener('click', function (event) {
            navigator.clipboard.writeText(innBlock.innerHTML);
            mousePopup('Скопировано', event)
        })


    }

}

const onPressCopyButton = () => {
    const wrapper = document.querySelector('.props-card');
    if (wrapper) {
        const copyButton = document.querySelector('.copy-card');
        const wrapperData = wrapper.querySelectorAll('.props-item');

        let textToCopy = '';

        [...wrapperData].forEach(function (item) {
            let string = item.innerText.replace(/\s+/g, ' ').trim()
            textToCopy += (string + `\n`).toString();
        })

        copyButton.addEventListener('click', function (event) {
            navigator.clipboard.writeText(textToCopy);
            mousePopup('Скопировано', event)
        })
    }
}

const getValue = (input) => parseFloat(input.value.replaceAll(' ', '') || 0) * 100

const submitInput = (_type) => {
    let amount;
    if (_type === 'sum') {
        amount = (+masked.unmaskedValue).toFixed(2) * 100 || 0
        const hiddenInput = modal.querySelector('.overall-amount__wrapper .overall-amount');
        hiddenInput.value = amount;
        const hiddenInput2 = modal.querySelector('.overall-amount2__wrapper .overall-amount2');
        hiddenInput2.value = (+maskedCommission.unmaskedValue).toFixed(2) * 100 || 0;
    } else {
        amount = (+maskedCommission.unmaskedValue).toFixed(2) * 100 || 0
        const hiddenInput2 = modal.querySelector('.overall-amount2__wrapper .overall-amount2');
        hiddenInput2.value = amount;
    }

    if (amount <= 0) return;

    const name = 'amount' + amount + _type;

    if (cache.exist(name)) {
        const res = cache.get(name);
        commissionInsert(res, _type);
    } else {
        commissionFetching = true;
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
        timer = setTimeout(async () => {

            if (error) return;

            if (commissionLoader) {
                commissionLoader.classList.add('modal__loader-enabled')
            }

            let res = {};
            let body;
            const company = document.location.href.split('/company/')[1].split('/deck')[0];
            if (_type === 'sum') {
                body = {amount: 0, amount2: amount, company, type};
            } else {
                body = {amount, company, type}
            }

            try {
                res = await api.post({href: '/getCommission', body})
            } catch (e) {
                if (tries > 0) tries--;
                else error = true;
            }

            cache.set(name, res);

            if (commissionLoader) {
                commissionLoader.classList.remove('modal__loader-enabled')
            }

            commissionFetching = false;
            commissionInsert(res, _type);
        }, 1000)
    }
}

const commissionInsert = (res, type) => {
    const {
        payload = {add_total: 0, total: 0, percent: 0, const_pay: 0, message: 'Произошла непредвиденная ошибка'},
        status = 'Error'
    } = res;
    const {add_total, total, percent, const_pay, message} = payload;

    let totalSum;
    const commissionSum = document.querySelector('.commission-sum');
    const commissionPer = document.querySelector('.commission-percent');
    const commissionConst = document.querySelector('.const-pay');

    let wrapper;
    if (type === 'sum') {
        wrapper = sumWrapper;
        totalSum = document.querySelector('.commission-input');
        const hiddenInput = modal.querySelector('.overall-amount2__wrapper .overall-amount2');
        hiddenInput.value = total;
    } else {
        wrapper = amountWrapper;
        totalSum = document.querySelector('.sum-of-transaction');
        const hiddenInput = modal.querySelector('.overall-amount__wrapper .overall-amount');
        hiddenInput.value = total;
    }

    if (message) {
        if (status === 'Error') {
            if (tries > 0) {
                tries--;
            } else {
                error = true
            }
        }

        inlineInputAddHint(wrapper, message);

        if (commissionSum) commissionSum.innerText = currencyFormat(0);

        if (totalSum) totalSum.value = 0;

        if (commissionPer) commissionPer.innerText = '';

        if (commissionConst) commissionConst.innerText = '';

    } else {
        inlineInputRemoveHint(wrapper);
    }

    if (commissionSum && add_total) {
        commissionSum.innerText = currencyFormat(add_total / 100);
    }

    if (totalSum && total) {
        totalSum.value = total / 100;
    }

    if (commissionPer && percent) {
        commissionPer.innerText = +percent + ' %';
    }

    if (commissionConst && const_pay) {
        commissionConst.innerText = ' + ' + currencyFormat(const_pay / 100);
    }
    if (status !== 'Error') {
        const hiddenInput = modal.querySelector('.overall-amount__wrapper .overall-amount');
        hiddenInput.value = (sumInput.value.match(/\d/g) ?? []).join("") * 100 || 0;
    }
}







const buttonBlock = (modal) => {
    const cancelButton = modal.querySelector('.cancel__button');
    cancelButton?.addEventListener('click', () => {
        const portal = document.querySelector('.blur-portal__bid');
        portal.classList.toggle("animate");
        setTimeout(() => portal.classList.toggle("active"), 400);
    })
}




const toastAutoShow = () => {
    const toastHolder = document.querySelector('.no-auto-toast');
    if (toastHolder) return;
    const toast = $(".toast");
    setTimeout(() => toast.show(), 100);
    const delayTimeout = +toast.data("delay");
    setTimeout(() => toast.hide(), delayTimeout);
}

export default mainScreen;
