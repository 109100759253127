import customSelect from "custom-select/src";
import {
  checkC2w,
  getAmountInput,
  getInput,
  getOnlyDigits,
  inputCard2w,
  singleSelectorChecker,
  submitDisabler
} from "./convert";

const debug = false;

const log = (...values) => debug ? console.info(...values) : null;
const warn = (...values) => debug ? console.warn(...values) : null;
const err = (...values) => debug ? console.error(...values) : null;

const TRUSTED_CLASS_LIST = [
  'user-external-add'
];
const main = () => {
  // Проверка на то, где должен исполняться файл
  log('Start mount external add script');
  let exist = false;
  TRUSTED_CLASS_LIST.map(name => exist = document.querySelector('.'+name) || exist);
  if (!exist) return warn('Exist external add script. Trusted classlists not found');
  // Создаём красивые селекторы
  const selectors = document.querySelectorAll('.external-add');
  const generated_selectors = customSelect(selectors);
  log('Selectors generated');
  const usdt_section = document.querySelector('.usdt-add')
  if (usdt_section) {
    log('generate qr-code');
    const code_wrapper = usdt_section.querySelector('.qr-code')
    log(code_wrapper);
    const qrcode = require('yaqrcode');
    code_wrapper.style.backgroundImage = `url(${qrcode(code_wrapper.dataset.code, {size: 142})})` ;
    log('qr-code generated')
  }

  // Вешаем слушатели на каждый селектор
  selectors.forEach((selector, key) => {

    const current_options = selector.querySelectorAll('option');
    const current_currency = [...current_options] // Приводим к нормальному иттеративному типу
      .filter(option => option.value === selector.value) // Получаем массив с нужной опцией селектора
      [0].dataset.currency; // Достаём из неё поле data-currency

    toggleType(current_currency);// Показываем нужную секцию при старте

    selector.addEventListener('change', () => {
      // Получаем выбранную валюту
      const current_options = selector.querySelectorAll('option');
      const current_currency = [...current_options] // Приводим к нормальному иттеративному типу
        .filter(option => option.value === selector.value) // Получаем массив с нужной опцией селектора
        [0].dataset.currency; // Достаём из неё поле data-currency

      if (!current_currency) err("Can't get selector's currency!")
      log('Selected currency: ', current_currency);
      // индекс может быть только 0 или 1, следовательно приводим к булевому значению,
      // инвертируем и приводим к числовому
      const opposite_selector = selectors[+!!!key];
      const opposite_options = opposite_selector.querySelectorAll('option');
      const opposite_value = [...opposite_options] // Приводим к нормальному иттеративному типу
        .filter(option => option.dataset.currency === current_currency) // Получаем массив с нужной опцией селектора
        [0].value; // Достаём значение

      if (!opposite_value) return err("Can't find opposite option value for currency: ", current_currency);
      opposite_selector.value = opposite_value; // Присваем значение
      opposite_selector.customSelect.value = opposite_value; // И тут на всякий случай тоже
      log('Changed currency for opposite selector! Key: ', +!!!key, 'Value: ', opposite_value);

      toggleType(current_currency); // Показываем нужную секцию
    })
  })


  const amountInput = getAmountInput();
  amountInput.addEventListener('input', () => checkC2w());

  const cardInput = getInput();
  cardInput.addEventListener('input', () => {
    const cardInput = getInput();
    const value = getOnlyDigits(cardInput.value);
    inputCard2w(value, cardInput)
  })
  const button = document.querySelector('.replenishment__button');
  button.addEventListener('click', (e) => submitDisabler(e));
}

const toggleType = (currency = 'RUB') => {
  const usdt_section = document.querySelector('.usdt-add')
  const card_section = document.querySelector('.card-add')
  if (currency === 'RUB') {
    usdt_section.classList.add('d-none')
    card_section.classList.remove('d-none')
  } else if (currency === 'USD') {
    usdt_section.classList.remove('d-none')
    card_section.classList.add('d-none')
  }
}

export default main;