const activateAccountPage = () => {

    const wrapperAuth = document.querySelector('.activate-account__page');
    const wrapperLoggedIn = document.querySelector('.blur-portal__email-activate');
    if (wrapperAuth) {
        showMailSendProblems();
    }

    if (wrapperLoggedIn) {
        showMailSendProblems();
    }
}

const showMailSendProblems = () => {
    const button = document.getElementById("no_code");
    button?.addEventListener("click", () => {
        const hint = document.querySelector(".code-problem-hint");
        hint?.classList?.toggle("d-none");
    });
}

export default activateAccountPage;