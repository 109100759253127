import $ from 'jquery';
import {InputMask} from "imask";
import {SUM_MASK} from "../../variables";
import Cache from '../../compenents/CacheController';
import api from '../../compenents/ApiController';
import {currencyFormat, inlineInputAddHint, inlineInputRemoveHint} from "../../helpers";

const cache = new Cache();

const main = () => {
  toggleModal('.cancel-acquiring', '.blur-portal__cancel_acquiring', '.refuse__button', true)
  toggleModal('.transfer-acquiring', '.blur-portal__transfer_acquiring', '.cancel__button', true );
  toggleModal('.transfer-acquiring', '.blur-portal__transfer_acquiring_ver2', '.cancel__button', true );
  toggleModal('.delete-shop', '.blur-portal__delete-shop-acquiring', '.cancel__button');
  toggleModal('.acquiring-row', '.blur-portal__acquiring_info', '.cancel__button');
  toggleModal('.refund__button', '.blur-portal__refund_acquiring', '.cancel__button');
  acquiringTransferModal();
}


const toggleModal = (buttonClass, modalClass, cancelButtonClass, inMainWrapper = false) => {
  let buttonWrapper = document;
  if (inMainWrapper) {
    buttonWrapper = document.querySelector('.main-button-wrapper');
    if (!buttonWrapper) return;
  }
  const triggerButton = buttonWrapper.querySelectorAll(buttonClass);
  const modal = document.querySelector(modalClass);
  if (!(triggerButton && modal)) return;
  triggerButton.forEach(button => {
    button.addEventListener('click', () => {
      modal.classList.toggle("active");
      setTimeout(() => modal.classList.toggle("animate"), 100);
    })
  })
  const cancelButton = modal.querySelector(cancelButtonClass);
  cancelButton.addEventListener('click', () => {
    const _modal = $(modalClass);
    _modal.toggleClass("animate");
    setTimeout(() => _modal.toggleClass("active"), 400);
  })
}

const modal = document.querySelector('.blur-modal__transfer_acquiring_ver2');
const sumInput = document.querySelector('.blur-modal__transfer_acquiring_ver2 .inline-input.sum-of-transaction');
const commissionInput = document.querySelector('.blur-modal__transfer_acquiring_ver2 .commission-input');
const badges = document.querySelectorAll('.blur-modal__transfer_acquiring_ver2 .badge p');
const commissionLoader = document.querySelector('.blur-modal__transfer_acquiring_ver2 .commission-wrapper .modal__loader');
const sumWrapper = document.querySelector('.blur-modal__transfer_acquiring_ver2 .sum-wrapper');
const amountWrapper = document.querySelector('.blur-modal__transfer_acquiring_ver2 .amount-wrapper');
let output;
let masked;
if (sumInput) output = new InputMask(sumInput, SUM_MASK);
if (commissionInput) masked = new InputMask(commissionInput, SUM_MASK);

const hiddenInput = document.querySelector('.blur-modal__transfer_acquiring_ver2 .overall-amount__wrapper .overall-amount');

const type = 'acquiring';

let commissionFetching = false;

let timer = null;

let error = false;

let tries = 2;
const acquiringTransferModal = () => {
  if (!modal) return;
  if (!sumInput) return;


  const _type = 'sum'

  sumInput.addEventListener('input', () => {
    submitInput('sum');
    masked.updateValue();
    hiddenInput.value = (+output.unmaskedValue).toFixed(2) * 100 + '';
  })
  commissionInput.addEventListener('input', () => {
    submitInput('commission');
    output.updateValue();
  })

  badges?.forEach(badge => badge.addEventListener('click', () => {
    output.value = badge.getAttribute('data-value');
    submitInput('sum');
    masked.updateValue();
  }));

  // commissionOutput.addEventListener('input', () => {
  //   masked.value = (((+output.unmaskedValue + (+constPay / 100))  / ((100 - +commission))) * 100) + '';
  //   masked.updateValue();
  //   hiddenInput.value = (+output.unmaskedValue).toFixed(2) * 100
  // })
}

const submitInput = (_type) => {
  let amount;
  if (_type === 'sum') {
    amount =  output.unmaskedValue * 100 || 0
  } else {
    amount = masked.unmaskedValue * 100 || 0
  }

  if (amount <= 0) return;

  const name = 'amount' + amount + _type;

  if (cache.exist(name)) {
    const res = cache.get(name);
    commissionInsert(res, _type);
  } else {
    commissionFetching = true;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(async () => {

      if (error) return;

      if (commissionLoader) {
        commissionLoader.classList.add('modal__loader-enabled')
      }

      let res = {};
      let body;
      const company = document.location.href.split('/company/')[1].split('/service/')[0];
      if (_type === 'sum') {
        body = {amount: 0, amount2: amount, company, type};
      } else {
        body = {amount, company, type}
      }

      try {
        res = await api.post({href: '/getCommission', body})
      } catch (e) {
        if (tries > 0) tries--;
        else error = true;
      }

      cache.set(name, res);

      if (commissionLoader) {
        commissionLoader.classList.remove('modal__loader-enabled')
      }

      commissionFetching = false;
      commissionInsert(res, _type);
    }, 1000)
  }
}



const commissionInsert = (res, type) => {
  const {
    payload = {add_total: 0, total: 0, percent: 0, const_pay: 0, message: 'Произошла непредвиденная ошибка'},
    status = 'Error'
  } = res;
  const {add_total, total, percent, const_pay, message} = payload;

  let totalSum;
  const commissionSum = document.querySelector('.commission-sum');
  const commissionPer = document.querySelector('.commission-percent');
  const commissionConst = document.querySelector('.const-pay');

  let wrapper;
  if (type === 'sum') {
    wrapper = sumWrapper;
    totalSum = document.querySelector('.commission-input');
  } else {
    wrapper = amountWrapper;
    totalSum = document.querySelector('.sum-of-transaction');
  }

  if (message) {
    if (status === 'Error') {
      if (tries > 0) {
        tries--;
      } else {
        error = true
      }
    }

    inlineInputAddHint(wrapper, message);

    if (commissionSum) commissionSum.innerText = currencyFormat(0);

    if (totalSum) totalSum.value = 0;

    if (commissionPer) commissionPer.innerText = '';

    if (commissionConst) commissionConst.innerText = '';

  } else {
    inlineInputRemoveHint(wrapper);
  }

  if (commissionSum && add_total) {
    commissionSum.innerText = currencyFormat(add_total / 100);
  }

  if (totalSum && total) {
    totalSum.value = total / 100;
  }

  if (commissionPer && percent) {
    commissionPer.innerText = +percent + ' %';
  }

  if (commissionConst && const_pay) {
    commissionConst.innerText = ' + ' + currencyFormat(const_pay / 100);
  }

  if (status !== 'Error') {
    hiddenInput.value = (sumInput.value.match(/\d/g) ?? []).join("") * 100 || 0;
  }
}


export default main;
