
const modalReferral = () => {
 const wrapper = document.querySelector('.blur-modal__send_referral-modal')

    if (wrapper) {

        onPressSmsButton();
        radioButton();
        onPressCopyButton()

    }
}

const onPressSmsButton = () => {
    const smsButton = document.querySelector('.sms-referral')
    const phoneInput = document.querySelector('.phone-input__container')
    const smsIcon = document.querySelector('.sms')

    smsButton.addEventListener('click', function () {
        if (smsIcon.classList.contains('active')) {

            smsIcon.classList.remove('active');
            phoneInput.classList.add('hide');

        } else {

            smsIcon.classList.add('active')
            phoneInput.classList.remove('hide');

        }
    })
}

const onPressCopyButton = () => {
    const copyButton = document.querySelector('.copy-referral')
    const referralLink = document.querySelector('.link-referral-text')

    copyButton.addEventListener('click', function () {
        navigator.clipboard.writeText(referralLink.innerHTML);
    })

}

const radioButton = () => {
    const radioLinkReferral = document.querySelector('.link-referral')
    const radioEmailReferral = document.querySelector('.email-referral')

    const containerInviteLink = document.querySelector('.container_invite-link');
    const containerInviteEmail = document.querySelector('.container_invite-email');

    radioLinkReferral.addEventListener('change', function () {
        containerInviteLink.classList.remove('hide')
        containerInviteEmail.classList.add('hide')
    })

    radioEmailReferral.addEventListener('change', function () {
        containerInviteLink.classList.add('hide')
        containerInviteEmail.classList.remove('hide')
    })
}

export default modalReferral;