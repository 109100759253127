import {inlineInputAddHint, inlineInputRemoveHint} from "../../helpers";

let resident = true;

const main = () => {
  const radioWrapper = document.querySelector('.resident__radio');
  if (radioWrapper) {
    const residentInput = radioWrapper.querySelector('.resident-input');
    const notResidentInput = radioWrapper.querySelector('.not-resident-input');
    const button = document.querySelector('.check-inn__button');
    const buttonText = button.querySelector('.btn__default-text')
    notResidentInput.addEventListener('click', () => {
      resident = residentInput.checked;
      toggleInput();
      buttonText.innerHTML= 'Продолжить'
    })
    residentInput.addEventListener('click', () => {
      resident = residentInput.checked;
      toggleInput();
      buttonText.innerHTML= 'Проверить ИНН'
    })

    const innInput = document.querySelector('.inn');
    const wrapper = document.querySelector('.company-inn__wrapper.inline-input__wrapper');

    button?.addEventListener('click', () => {
      if (innInput.value.length === 0 ) {
        inlineInputRemoveHint(wrapper);
        inlineInputAddHint(wrapper, 'Требуется ИНН');
        innInput.addEventListener('input', () => {
          inlineInputRemoveHint(wrapper);
        })
      } else if (innInput.value.length < 12) {
        inlineInputRemoveHint(wrapper);
        inlineInputAddHint(wrapper, 'ИНН Должен быть не короче 12 и не длиннее 15 цифр');
        innInput.addEventListener('input', () => {
          inlineInputRemoveHint(wrapper);
        })
      }
    })

  }
  const addRadioWrapper = document.querySelector('.add-company__radio');
  if (addRadioWrapper) {
    const addResidentInput = addRadioWrapper.querySelector('.resident-input');
    const alertWarning = document.querySelector('.removable-alert');
    addRadioWrapper.addEventListener('change', () => {
    if (!addResidentInput.checked) {
      const ogrnInput = document.querySelector('.ogrn-col');
      const innInput = document.querySelector('.inn-col');
      const shortNameInput = document.querySelector('.short-name-col');


      alertWarning.classList.add('d-none');

      ogrnInput.classList.add('d-none');
      innInput.classList.add('d-none');
      shortNameInput.classList.remove('ps-2')
      shortNameInput.classList.remove('col-3')
      shortNameInput.classList.add('pe-2')
      shortNameInput.classList.add('col-5')


    }
    })
    addRadioWrapper.addEventListener('change', () => {
      if (addResidentInput.checked) {
        const ogrnInput = document.querySelector('.ogrn-col');
        const innInput = document.querySelector('.inn-col');
        const shortNameInput = document.querySelector('.short-name-col');
        alertWarning.classList.remove('d-none');
        ogrnInput.classList.remove('d-none');
        innInput.classList.remove('d-none');
        shortNameInput.classList.add('ps-2')
        shortNameInput.classList.add('col-3')
        shortNameInput.classList.remove('pe-2')
        shortNameInput.classList.remove('col-5')
      }
    })

    toggleAlert();
  }
}



const toggleInput = () => {
  const input = document.querySelector('.company-inn__wrapper');
  if (resident) {
    if (input.classList.contains('d-none')) {
      input.classList.remove('d-none')
    }
  } else {
    if (!input.classList.contains('d-none')) {
      input.classList.add('d-none')
    }
  }
}

const toggleAlert = () => {

  const inputs = document.querySelectorAll('input[type=text]');

  const alertWarning = document.querySelector('.removable-alert');

  [...inputs].forEach(input => input.addEventListener('input', function (event) {

    let emptyInputs = [...inputs].every(elem => elem.value === '' && elem.defaultValue === '');

    if (emptyInputs) {
      alertWarning.classList.add('d-none')
    }

    if ((event.target.classList.contains('short-name') || event.target.classList.contains('full-name') || event.target.classList.contains('address')) && event.target.value !== '') {
      alertWarning.classList.remove('d-none')
    }
  }));
}


export default main;
