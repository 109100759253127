import iMask from "imask";
import {PHONE_OPTIONS} from '../../variables'
import {inputDebounced, isValid, removeErrorHint} from "../../helpers";

const modalWrapper = document.querySelector('.modal__wrapper');

const phonePage = () => {
    const phoneInput = modalWrapper.querySelector('.phone-input');

    if (phoneInput) {

        window.addEventListener("pagehide", () => {
            phoneInput.value = '+7';
        });

        const phoneMask = iMask(phoneInput, PHONE_OPTIONS);

        phoneMask.updateValue()

        phoneInput.addEventListener('paste', (event) => {

            let paste = (event.clipboardData || window.clipboardData).getData('text');
            let formatPaste = (paste.match(/\d/g) ?? []).join("");

            if (formatPaste.length === 11) {
                const inputWrapper = document.querySelector('.input__wrapper');
                removeErrorHint(inputWrapper);
                phoneSubmitButton.style.opacity = null;
                phoneMask.value = formatPaste;
                event.preventDefault();
            }

        })

        const phoneSubmitButton = modalWrapper.querySelector('#send_phone');

        phoneSubmitButton.style.opacity = 0.7;


        if (phoneSubmitButton) {

            const textBlock = document.querySelector('.block_button');
            const timeNow = new Date();

            if (textBlock) {
                const minutesToAdd = 5;
                const currentDate = new Date();
                const futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

                sessionStorage.setItem('unblock-login', futureDate.toString())
            }

            if (Date.parse(sessionStorage.getItem('unblock-login')) && Date.parse(sessionStorage.getItem('unblock-login')) - timeNow > 0) {

                phoneSubmitButton.disabled = true

                let textPhoneSubmitButton = phoneSubmitButton.querySelector('p')

                const unBlockTime = Date.parse(sessionStorage.getItem('unblock-login'))

                let interval = setInterval(function () {

                    const now = new Date();

                    let distance = unBlockTime - now;

                    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                    textPhoneSubmitButton.innerHTML = minutes + ':' + (seconds < 10 ? '0' + seconds: seconds)

                    if (distance <= 0) {
                        clearInterval(interval);
                        phoneSubmitButton.disabled = false
                        textPhoneSubmitButton.innerHTML = 'Продолжить'
                    }

                }, 1000)

            } else {
                inputDebounced(phoneInput, () => phoneInputPassCallback(phoneInput.value), 16);
                phoneSubmitButton.addEventListener('click', () => {
                    const {value} = phoneInput;

                    const formPhone = document.querySelector('.form-phone-input')
                    phoneInputPassCallback(phoneInput.value);
                    if (value.length !== 0 && isValid(value)) {
                        /*modalLoading();*/
                        formPhone.submit();
                    }
                })
            }
        }
    }
}

const phoneInputPassCallback = (value) => {
    const inputWrapper = document.querySelector('.input__wrapper');
    const phoneSubmitButton = modalWrapper.querySelector('#send_phone');
    if (isValid(value)) {
        phoneSubmitButton.style.opacity = null;
        phoneSubmitButton.disabled = false;
        removeErrorHint(inputWrapper);
    } else {
        phoneSubmitButton.style.opacity = 0.7;
    }
};

export const submitButtonDisable = () => {
    const inputPhone = document.querySelector('.phone-input');
    const phoneSubmitButton = document.querySelector('.btn__default-primary');

    inputPhone.addEventListener('input', function () {

        const phoneValue = (inputPhone.value.match(/\d/g) ?? []).join("");

        if (Date.parse(sessionStorage.getItem('unblock-login')) - new Date() <= 0) {
            phoneSubmitButton.disabled = !isValid(phoneValue);
        }



    })

}

export default phonePage;
