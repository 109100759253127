/*// Load Styles
import '../../scss/main.scss';
import '../../scss/landing-v2/fonts/GothamPro-Medium.woff';
import {initBootstrap} from "../bootstrap";

initBootstrap({
  tooltip: false,
  popover: false,
  toasts: false
});

import $ from "jquery";*/

// DROPDOWN

export function dropDownBlockUser() {
  const dropdown = document.querySelector('.list-documents');

  if (dropdown) {
    dropdownToggle(dropdown);
    inputsLoadFile(dropdown);
  }
}

function dropdownToggle(dropdown){

  if (dropdown) {
    const dropdownHeader = dropdown.querySelector('.header-list-documents')
    dropdownHeader.addEventListener('click', function () {

      if (dropdown.classList.contains('active')) {
        dropdown.classList.remove('active')
      } else {
        dropdown.classList.add('active')
      }

    })
  }
}

function inputsLoadFile(dropdown) {
  const inputsFile = dropdown.querySelectorAll('.uploader-container-block');

  [...inputsFile].forEach(element => {

    element.querySelector('input').addEventListener('input', function (e, elem = element) {
      console.log('куку', e.target.files.length);

      const uploadedFileWrapper = elem.querySelector('.uploaded-file');

      const selectedFileText = uploadedFileWrapper.querySelector('p');
      console.log('selectedFileText', selectedFileText);
      const descriptionText = elem.querySelector('.format-description');

      if (e.target.files.length > 0) {

        selectedFileText.textContent = e.target.files[0].name;
        descriptionText.classList.add('d-none')
        uploadedFileWrapper.classList.remove('d-none');
      } else {
        descriptionText.classList.remove('d-none')
        uploadedFileWrapper.classList.add('d-none');
      }
    })

    const uploadedFileWrapper = element.querySelector('.uploaded-file');

    const clearInputBtn = uploadedFileWrapper.querySelector('#clear-input');
    const descriptionText = element.querySelector('.format-description');


    clearInputBtn.addEventListener('click', function() {
      uploadedFileWrapper.classList.add('d-none');
      descriptionText.classList.remove('d-none');
      element.value = null;
    })


  } )

}





