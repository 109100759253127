import iMask from "imask";
import {PHONE_OPTIONS} from "../../variables";
import {inlineInputRemoveHint} from "../../helpers";

const main = () => {
  const shopWrapper = document.querySelector('.shop-info');
  const supervisorWrapper = document.querySelector('.supervisor-info');
  if (supervisorWrapper) {
    const supervisorInputs = supervisorWrapper.querySelectorAll('.inline-input__wrapper-error');
    removeHintOnInput(supervisorInputs);
    const phoneInput = supervisorWrapper.querySelector('.inline-input.tel');
    iMask(phoneInput, PHONE_OPTIONS);
  }
  if (shopWrapper) {
    const shopInputs = shopWrapper.querySelectorAll('.inline-input__wrapper-error');
    removeHintOnInput(shopInputs);
  }
}

const removeHintOnInput = (array) => array.forEach(el => el.addEventListener('input', () => inlineInputRemoveHint(el)))

export default main;