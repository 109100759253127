import { MAIL_MASK } from "../../variables";
import iMask from "imask";
import {
  modalLoading,
  addErrorHint,
  removeErrorHint,
  inputDebounced,
  mailValidate,
  toggleInput,
  passValidate,
  doublePassInputs
} from "../../helpers";

let checkboxValid = false;

const userRegisterPage = () => {
  const passInput = document.querySelector(".reg-password-input");
  const passDuplicated = document.querySelector(
    ".reg-password-input-duplicated"
  );
  const passInputs = [passInput, passDuplicated];
  const inputWrapper = document.querySelectorAll(".input__wrapper");
  const wrappers = inputWrapper;
  const mailInput = document.querySelector(".reg-email-input");
  iMask(mailInput, MAIL_MASK);
  mailInputValidate(mailInput, inputWrapper[2]);

  passInput?.addEventListener("input", () => {
    removeErrorHint(wrappers[0]);
    removeErrorHint(wrappers[1]);
  });
  passDuplicated?.addEventListener("input", () => {
    removeErrorHint(wrappers[1]);
  });
  mailInput?.addEventListener("input", () => {
    removeErrorHint(wrappers[2]);
  });

  toggleInput(passInputs[0], inputWrapper[0]);
  toggleInput(passInputs[1], inputWrapper[1]);
  doublePassInputs(passInputs, inputWrapper);

  const regCheckbox = document.querySelector(
    ".registration_checkbox .checkbox_input"
  );
  checkboxInputValidate(regCheckbox);

  const submitButton = document.getElementById("reg_user");
  submitButton?.addEventListener("click", () => {
    let returns = false;
    if (passInputs[0]?.value.length === 0) {
      returns = true;
      addErrorHint(wrappers[0], "Это поле обязательно для заполнения");
    }
    if (passInputs[1]?.value.length === 0) {
      addErrorHint(wrappers[1], "Это поле обязательно для заполнения");
      returns = true;
    }
    if (mailInput?.value.length === 0) {
      addErrorHint(wrappers[2], "Это поле обязательно для заполнения");
      returns = true;
    }
    if (!checkboxValid) {
      if (
        document
          .querySelector(".registration_checkbox")
          ?.querySelector(".input__error-hint")
      )
        return;
      const paragraph = document.createElement("p");
      paragraph.classList.add("input__error-hint");
      paragraph.appendChild(
        document.createTextNode("Это поле обязательно для заполнения")
      );
      document.querySelector(".registration_checkbox")?.appendChild(paragraph);
      returns = true;
    }
    if (returns) return;
    const passValid = passValidate(passInputs[0]?.value);
    const passDuplicatedValid = passInputs[1]?.value === passInputs[0]?.value;
    const emailValid = mailValidate(mailInput?.value);
    if (
      passValid &&
      passDuplicatedValid &&
      emailValid &&
      checkboxValid &&
      passInputs[0]?.value.length >= 10
    ) {
      // REGEISTRATION_SUBMIT();
      modalLoading();
      document.getElementsByTagName("form")[0].submit();
    }
  });
};

/**
 * Валидация почты
 * @param {Element} input - Input почты
 * @param {Element} wrapper - Обёртка для него
 * @return null;
 * */

const mailInputValidate = (input, wrapper) => {
  inputDebounced(input, () => {
    const { value } = input;
    const isValid = mailValidate(value);
    if (isValid || value?.length === 0) {
      removeErrorHint(wrapper);
    } else {
      addErrorHint(wrapper, "Неверная почта");
    }
  });
};

const checkboxInputValidate = input => {
  input?.addEventListener("click", () => {
    document
      .querySelector(".checkbox__wrapper")
      ?.querySelectorAll(".input__error-hint")
      ?.forEach(el => el.remove());
    input?.classList.toggle("checkbox_input-active");
    checkboxValid = input?.classList.contains("checkbox_input-active");
  });
};

export default userRegisterPage;
