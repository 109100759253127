import iMask from "imask";
import {PHONE_OPTIONS} from '../../variables'
import {inputDebounced, isValid, modalLoading, removeErrorHint, toggleInput} from "../../helpers";
import {inputPassCallback} from "./passPage";

const modalWrapper = document.querySelector('.modal__wrapper-shop');

const loginPagePay = () => {

    const phoneInput = modalWrapper.querySelector('.phone-input');
    const passwordInput = modalWrapper.querySelector('.password-input');
    const formSubmitButton = modalWrapper.querySelector('#login_button');

    submitButtonDisable();

    if (phoneInput && passwordInput) {

        // phoneInput
        window.addEventListener("pagehide", () => {
            phoneInput.value = '+7';
        });

        const phoneMask = iMask(phoneInput, PHONE_OPTIONS);

        phoneMask.updateValue()

        phoneInput.addEventListener('paste', (event) => {

            let paste = (event.clipboardData || window.clipboardData).getData('text');
            let formatPaste = (paste.match(/\d/g) ?? []).join("");

            if (formatPaste.length === 11) {

                phoneMask.value = formatPaste;

                event.preventDefault();
            }
        });

        // passwordInput

        toggleInput(passwordInput);
        inputDebounced(passwordInput, () => inputPassCallback(passwordInput.value), 3000);

        if (formSubmitButton) {

            // phoneInput
            inputDebounced(phoneInput, () => phoneInputPassCallback(phoneInput.value), 16);
            formSubmitButton.addEventListener('click', () => {
                const {value} = phoneInput;

                const formPhone = document.querySelector('.form-phone-input')
                phoneInputPassCallback(phoneInput.value);
                if (value.length !== 0 && isValid(value)) {
                    /*modalLoading();*/
                    formPhone.submit();
                }
            })

            // passwordInput

            formSubmitButton.addEventListener('click', () => {
                const {value} = passwordInput
                inputPassCallback(passwordInput.value);
                if (value.length !== 0 && value.length >= 10) {
                    modalLoading();
                    document.getElementsByTagName('form')[0].submit()
                }
            })
        }

    }
}

const phoneInputPassCallback = (value) => {
    const inputWrapper = document.querySelector('.input__wrapper');
    const formSubmitButton = modalWrapper.querySelector('#login_button');
    if (isValid(value)) {
        formSubmitButton.disabled = false;
        removeErrorHint(inputWrapper);
    }
};

const submitButtonDisable = () => {
    const inputPhone = document.querySelector('.phone-input');
    const formSubmitButton = modalWrapper.querySelector('#login_button');

    inputPhone.addEventListener('input', function () {

        const phoneValue = (inputPhone.value.match(/\d/g) ?? []).join("");

        formSubmitButton.disabled = !isValid(phoneValue);
    });
}


export default loginPagePay;
