let timeOutStatus = undefined;

export default function mousePopup(text, target) {

const popup = document.querySelector('.popup-grey');

if (popup) {

  if (typeof timeOutStatus !== undefined) {
    clearTimeout(timeOutStatus);
  }

  const textPopup = popup.querySelector('#popup-text');
  textPopup.innerHTML = text;

  popup.style.left = target.clientX + 20 + 'px';
  popup.style.top = target.clientY + 10 + 'px';

  popup.classList.remove('d-none')

  timeOutStatus = setTimeout(() => {
    popup.classList.add('d-none')
    removeEventListener('mousemove', mouseListener, true)
  }, 700)

// open
  const mouseListener = () => document.addEventListener('mousemove', function (e) {
    popup.style.left = e.clientX + 20 + 'px';
    popup.style.top = e.clientY + 10 + 'px';
  })
  mouseListener();
}
}

