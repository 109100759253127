import {addErrorHint, inputDebounced, modalLoading, removeErrorHint, toggleInput} from "../../helpers";

const passPage = () => {
  const passwordInput = document.querySelector('.password-input');
  if (passwordInput) {
    toggleInput(passwordInput);
    inputDebounced(passwordInput, () => inputPassCallback(passwordInput.value), 3000);
    const passSubmit = document.getElementById('password_submit');
    if (passSubmit) {
      passSubmit.addEventListener('click', () => {
        const {value} = passwordInput
        inputPassCallback(passwordInput.value);
        if (value.length !== 0 && value.length >= 10) {
          modalLoading();
          document.getElementsByTagName('form')[0].submit()
        }
      })
    }
  }
}

export const inputPassCallback = (value) => {
  const inputWrapper = document.querySelector('.input__wrapper.pass__wrapper');
  if (value.length === 0) {
    removeErrorHint(inputWrapper);
    addErrorHint(inputWrapper, 'Это поле обязательно для заполнения');
  } else if (value.length < 10) {
    removeErrorHint(inputWrapper);
    addErrorHint(inputWrapper, 'Неверный пароль');
  } else {
    removeErrorHint(inputWrapper);
  }
};

export default passPage;
