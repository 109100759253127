import {InputMask} from "imask";
import {SUM_MASK} from "../../variables";
import accountSelectors from "../../compenents/convert";
import externalAdd from "../../compenents/externalAdd";

const wrapper = document.querySelector('.transfer__inputs');
let sumInput;
let masked;
if (wrapper) {
    sumInput = wrapper.querySelector('.inline-input.summ');
    masked = new InputMask(sumInput, SUM_MASK);
}

const main = () => {
    const wrapper = document.querySelector('.transfer__inputs');
    const replenishmentWrapper = document.querySelector('.replenishment-input__value-container');
    if (wrapper) {
        accountSelectors();
        hiddenInput(wrapper);
        removeNotWalletModal();
    }

    if (replenishmentWrapper) {
        externalAdd();
        // accountSelectors();
        hiddenInput(replenishmentWrapper);
    }
}


const hiddenInput = (wrapper) => {
    if (wrapper) {
        const overallInput = wrapper.querySelector('.overall-amount__wrapper .overall-amount');
        const sumInput = wrapper.querySelector('.inline-input.summ');
        if (sumInput && overallInput) {
            if (overallInput.value && overallInput.value !== '0') {
                sumInput.value = (+overallInput.value / 100).toFixed(2);
            }
            sumInput.addEventListener('input', () => overallInput.value = (+sumInput.value.replaceAll(' ', '')).toFixed(2) * 100)
        }
    }
}

const removeNotWalletModal = () => {
    const modalNoWallet = document.querySelector('.blur-portal__wallet-not-found');
    if (!modalNoWallet) return;
    const cancelButtons = modalNoWallet.querySelectorAll('.cancel__button')
    cancelButtons.forEach(button => button.addEventListener('click', event => {
        modalNoWallet.classList.remove("animate");
        setTimeout(() => modalNoWallet.classList.remove("active"), 300);
    }))
}


export default main;
