import $ from "jquery";
import api from "./ApiController";

export default function dropdownNotification() {
    const wrapper = document.querySelector('.dropdown_notification');

    if (wrapper) {
        const bodyNotificationItems = wrapper.querySelector('.content-body')
        const notificationButton = document.querySelector('.notification-button');

        if (bodyNotificationItems) {

            const allIdNotification = bodyNotificationItems.querySelectorAll('[data-id]');

            // Initial

            // onClick notification btn
            notificationButton.addEventListener('click', function () {
                const dropdown = document.querySelector('.wallet-expanded');
                if (dropdown) dropdown.classList.remove('show-custom');
                const allIdNotification = bodyNotificationItems.querySelectorAll('[data-id]');

                removeRedCircle(allIdNotification);

            })

/*            // Dropdown notification close
            let observer = new MutationObserver(function (mutations) {
                mutations.forEach(async function (mutation) {
                    if (mutation.attributeName === "class") {
                        if (!$(mutation.target).hasClass('show')) {

                            removeRedCircle(allIdNotification);

                        }
                    }
                });
            });

            observer.observe(wrapper, {
                attributes: true
            });*/

        }
    }
}

async function removeRedCircle(allIdNotification) {

    let arrayIdNotification = [];
    allIdNotification.forEach(function (element) {

        arrayIdNotification.push(parseInt(element.dataset.id))
    });

    await api.post({href: '/setViewedNotification', body: {viewed_ids : arrayIdNotification}});

}