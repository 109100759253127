import {calcCommission, parseExtension, toastAutoHide} from "../../helpers";
import {ACCEPTED_TYPES, NUMBER_OF_ACCEPTED_FILES} from "../../variables";
import $ from "jquery";
import customSelect from "custom-select/src";

const main = () => {
  const wrapper = document.querySelector('.uploader-container');
  if (!wrapper) return;
  const fileInput = wrapper.querySelector('input[type=file]');
  if (!fileInput) return;
  const selectors = document.querySelectorAll('select');
  selectors.forEach(el => customSelect(el));
  fileInput.addEventListener('input', (e) => {
    const badges = wrapper.querySelectorAll('.file-name');
    if (badges.length >= NUMBER_OF_ACCEPTED_FILES) {
      badges.forEach(badge => badge.remove());
    }
    let error = false;
    const file = e.target.files[0]
    const extension = parseExtension(file.name);
    const size = file.size / 1024 / 1024;
    if (!ACCEPTED_TYPES.some(type => type === extension)) {
      toastAutoHide($('.toast.format-error'));
      fileInput.value = null;
      error = true;
    }
    if (size >= 10) {
      toastAutoHide($('.toast.size-error'));
      fileInput.value = null;
      error = true;
    }
    if (!error) {
      const description = document.querySelector('.format-description');
      if (!description.classList.contains('d-none')) description.classList.add('d-none');
      addBadge(wrapper, file.name, () => {
        fileInput.value = null;
        if (description.classList.contains('d-none')) description.classList.remove('d-none');
      });
    }
  })
  $('.btn__default-primary-rounded.mass_add').on('click', () => {
    const fileInput = wrapper.querySelector('.uploader-container input[type=file]');
    const exist = !!fileInput.files.length
    if (!exist) {
      toastAutoHide($('.toast.no-file'))
    }
  })
  massTable();
}

const addBadge = (wrapper = document, label = 'список выплат сотрудникам_new.xls', callback = () => null) => {
  const badge = createMulticlassElement('div', ['file-name', 'd-flex', 'align-items-center']);
  const text = createMulticlassElement('p', ['body-15-bold', 'color-grey-dark']);
  const icon = createMulticlassElement('div', ['icon__wrapper', 'icon__close']);
  text.appendChild(document.createTextNode(label))
  badge.appendChild(text);
  badge.appendChild(icon);
  wrapper.appendChild(badge);
  icon.addEventListener('click', () => {
    callback();
    badge.remove();
  })
}

const createMulticlassElement = (elementType, classes) => {
  if (!elementType || !classes) return;
  return multiclassAdd(document.createElement(elementType), classes)
}

const multiclassAdd = (element, array) => {
  array.forEach((item) => element.classList.add(item))
  return element;
}


const massTable = () => {
  const table = document.querySelector('.mass_error_table');
  if (!table) return;
  const items = table.querySelectorAll('.table-item');
  items.forEach(item => {
    const inputWrapper = item.querySelector('.table-input-outline');
    if (!inputWrapper) return
    const input = inputWrapper.querySelector('.table-item-value');
    if (!input) return
    input.addEventListener('focus', () => {
      inputWrapper.classList.toggle('active');
      item.querySelector('.table-input-error')?.classList.toggle('d-none');
    })
    input.addEventListener('blur', () => {
      inputWrapper.classList.toggle('active');
      item.querySelector('.table-input-error')?.classList.toggle('d-none');
    })
  })
  const rows = table.querySelectorAll('.table-row');
  rows.forEach(row => {
    const sumInput = row.querySelector('.sum-input');
    sumInput?.addEventListener('input', () => {
      const typeInput = row.querySelector('.type-input');
      const commissionInput = row.querySelector('.commission-input');
      const commissionData = row.querySelector('.commission-data')?.innerText;
      if (!(typeInput || commissionInput || commissionData)) return;
      if (typeInput.value === 'wallet') {
        const commission = (JSON.parse(commissionData)).LTD2Person;
        commissionInput.value = calcCommission( true, +commission.percent_per_op, +commission.const_pay, +sumInput.value);
      }

      if (typeInput.value === 'card') {
        const commission = (JSON.parse(commissionData)).LTD2Person;
        commissionInput.value = calcCommission( true, +commission.percent_per_op, +commission.const_pay, +sumInput.value);
      }

    })
  })
}

export default main;