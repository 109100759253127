import axios from "axios";

export class API {

    baseUrl = document.location.origin;

    constructor() {
        this.baseUrl = document.location.origin;
    }

    /**
     *
     * @param href
     * @param params
     */

    get = async ({href = '/', params = {}}) => {
        const url = this.baseUrl + href;
        try {
            const response = await axios.get(url, {params});
            if (response.statusText === 'OK') {
                return response.data;
            } else {
                console.error('error fetch ' + url);
            }
        } catch (e) {
            console.error('error fetch ' + url);
        }
    }

    /**
     *
     * @param href
     * @param params
     */
    post = async ({href = '/', body = {}}) => {
        const url = this.baseUrl + href;
        try {
            const response = await axios.post(url, body);

            if (response.statusText === 'OK') {
                return response.data;
            } else {
                console.error('error fetch ' + url);
                return {};
            }
        } catch (e) {
            console.error('error fetch ' + url);
            return {};
        }
    }
}

export default new API;