import {
  addErrorHint,
  doublePassInputs,
  modalLoading,
  passChecklist,
  passValidate,
  removeErrorHint,
  toggleInput
} from "../../helpers";

let passValid = false;
let passDuplicatedValid = false;
let requireError = false;

const passChangePage = () => {
  const passInputs = document.querySelectorAll('.new-password-input');
  const inputWrapper = document.querySelectorAll('.input__wrapper');
  const wrapper = [...inputWrapper];
  toggleInput(passInputs[0], inputWrapper[0]);
  toggleInput(passInputs[1], inputWrapper[1]);
  passInputs[0]?.addEventListener('input', () => {
    const {value} = passInputs[0]
    const isValid = passValidate(value)
    if (requireError) wrapper.map(input => removeErrorHint(input));
    passChecklist(isValid);
  });
  passInputs[1]?.addEventListener('input', () => {
    if (requireError) wrapper.map(input => removeErrorHint(input));
  })
  doublePassInputs(passInputs, inputWrapper)
  const submitButton = document.getElementById('new_password_submit');
  submitButton?.addEventListener('click', () => {
    passValid = passValidate(passInputs[0]?.value);
    passDuplicatedValid = passInputs[0].value === passInputs[1]?.value
    if (passValid && passDuplicatedValid && passInputs[0]?.value.length >= 10) {
      wrapper.map(input => removeErrorHint(input));
      modalLoading();
      document.getElementsByTagName('form')[0].submit();
    } else {
      requireError = true;
      if (!passValid) {
        removeErrorHint(wrapper[0]);
        addErrorHint(wrapper[0], 'Пароль недостаточно безопасный')
      }
      if (!passDuplicatedValid) {
        removeErrorHint(wrapper[1]);
        addErrorHint(wrapper[1], 'Пароли не совпадают');
      }
      if (passInputs[0]?.value.length === 0) {
        removeErrorHint(wrapper[0]);
        addErrorHint(wrapper[0], 'Это поле обязательно для заполнения')
      }
      if (passInputs[1]?.value.length === 0) {
        removeErrorHint(wrapper[1]);
        addErrorHint(wrapper[1], 'Это поле обязательно для заполнения')
      }
    }
  })
};

export default passChangePage;
