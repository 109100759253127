import api from "./ApiController";

const generateConvertBody = (id) => ({
    id: id,
    active: true,
    closed: true,
})

const serviceAlert = () => {
  const wrapper = document.querySelector('.alert-storage')

    if (wrapper) {
        const closeBtn = wrapper.querySelector('.icon__close');
        const dataId = wrapper.dataset.id;

        closeBtn.addEventListener('click', async function () {
            const body = generateConvertBody(dataId)

            const ref = await api.post({href: '/setServiceAlert', body});

        })


    }

}



export default serviceAlert;
