import IMask from "imask";

export const PHONE_OPTIONS = {
  mask: "+{7} (000) 000-00-00",
  prepare: (appended, masked) => {
    if (appended === '8' && masked.value === '') {
      return '';
    }
    return appended;
  },
};

export const CODE_MASK = {mask: "000-000"};

export const MAIL_MASK = {mask: /^\S*@?\S*$/};

export const PASSPORT_MASK = {mask: "0000 000000"};

export const DATE_MASK = {mask: "00.00.0000"};

export const DOB_MASK = {
  mask: Date,
  max: new Date(new Date().setFullYear(new Date().getFullYear() - 18))
};

export const SUM_MASK = {
  mask: Number,
  min: 0,
  max: 1000000000,
  signed: false,
  thousandsSeparator: " ",
  radix: ".",
  mapToRadix: ["."],
  normalizeZeros: false
};

export const ACCEPTED_TYPES = ["xls", "xlsx"];

export const NUMBER_OF_ACCEPTED_FILES = 1;

export const INN_MASK = {mask: "000000000000000"};

export const OGRN_MASK = {mask: "0000000000000"};

export const CARD_NUMBER = {mask: "0000 0000 0000 0000 000"};

export const CARD_EXP_DATE = { mask: 'MM / YY', blocks: { YY: { mask: IMask.MaskedRange, from: 0, to: 50 }, MM: { mask: IMask.MaskedRange, from: 1, to: 12 } } };

export const CARD_CVC = {mask: "000"};

export const CODE_INITIAL_TIMER = window.location.code_execution_time || 60;

export const MAX_CODE_RETRY = window.location.code_execution_limit || 3;

export const CODE_ERROR_TEXT = window.location.code_error_text || [
  "Не удаётся подтвердить номер.",
  "Свяжитесь с нами"
];

export const SUPPORT_EMAIL =
  window.location.support_email || "mailto:support@vidi.pw";

/*export const PASS_SUBMIT = () => {
  if (window.location.pass_submit_callback && typeof window.location.pass_submit_callback === 'function') {
    window.location.pass_submit_callback();
  } else {
    return null;
  }
};

export const CODE_SUBMIT = () => {
  if (window.location.code_submit_callback && typeof window.location.code_submit_callback === 'function') {
    window.location.code_submit_callback();
  } else {
    return null;
  }
};
export const PHONE_SUBMIT = () => {
  if (window.location.phone_submit_callback && typeof window.location.phone_submit_callback === 'function') {
    window.location.phone_submit_callback();
  } else {
    return null;
  }
};

export const REGEISTRATION_SUBMIT = () => {
  if (window.location.registration_callback && typeof window.location.registration_callback === 'function') {
    window.location.registration_callback();
  } else {
    return null;
  }
}

export const NEW_PASS_SUBMIT = () => {
  if (window.location.new_pass_callback && typeof window.location.new_pass_callback === 'function') {
    window.location.new_pass_callback();
  } else {
    return null;
  }
}*/
