
export default class Cache {

    object = null;

    constructor() {
        this.object = {}
    }

    /**
     * @param {string} string
     * @return {*}
     */
    exist = (string) => this.get(string) !== undefined;

    /**
     * @param {string} string
     * @return {*}
     */
    get = (string) => this.object[string]

    /**
     * @param {string} string
     * @param {*} value
     */
    set = (string, value) => this.object[string] = value;

}